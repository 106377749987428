import React from 'react'
import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'

import Layout from '../../components/layout/layout'
import SEO from "../../components/seo"
import CCBreadcrumb from '../../components/layout/breadcrumb'
import SideBarSeOne from "../../components/layout/sidemenu/sidemenu_se_one"

import Alert from 'react-bootstrap/Alert'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'

import ContentHeader from '../../components/page-sections/content-header'
import FocusQuestion from '../../components/page-sections/focus-question'
import Introduction from '../../components/page-sections/introduction'
import ButtonTop from '../../components/buttons/button-top'
import ButtonNext from '../../components/buttons/button-next'

import rowImage from '../../queries/images/row-image'

import unit1Icon from '../../images/icons/unit_01_icon.svg'
import AntarticaConcentrations12 from '../../images/student-pages/unit-1/12_co2_concentrations_antartica.gif'
import AntarticaTemperatureVariations13 from '../../images/student-pages/unit-1/13_temp_variation_antartica.gif'
import AntarticaIceSheet15 from '../../images/student-pages/unit-1/15_antartica_ice_sheet.gif'
import TempRelation16 from '../../images/student-pages/unit-1/16_temp_relationship_antartica.gif'
import AntarticaIceSheet17 from '../../images/student-pages/unit-1/17_antartica_ice_sheet_labeled.gif'
import PresentPotentialVegetation18 from '../../images/student-pages/unit-1/18_present_potential_vegetation.gif'
import LastGlacialMax19 from '../../images/student-pages/unit-1/19_last_glacial_maximum.png'


const CarbonFromThePast12 = (props) => (
  <Layout location={props.location.pathname}>
  <SEO title="Carbon Connections - 1.2 Carbon from the Past" />
    <Container className="mb-5" fluid>
      <Row>
        <Col md="auto">
          <SideBarSeOne location={props.location.pathname} />
        </Col>
        <Col>
          <CCBreadcrumb
            pathname={props.location.pathname}
            title={'1.2 Carbon from the Past'}
            replace={props.replace}
          />
          <Alert
            className="w-100"
            variant="warning"
          >
            BSCS is maintaining this educational resource for archival purposes. Some information may be out of date. We are no longer supporting the resource for classroom use.
          </Alert>
          <Card>
            <Card.Body>

              <ContentHeader
                icon={ unit1Icon }
                iconAlt="Unit 1 icon"
                studentPageHeader="Unit 1: Carbon &amp; Climate in the Past"
                unitNumber="1.2"
                studentPageHeaderSub="Carbon from the Past"
                sectionA={ <>Ice Cores &mdash; CO<sub>2</sub> Records</> }
                sectionALink="/unit-1/1.2-carbon-from-the-past/#ice-cores"
                sectionB={ <>Ice Cores &mdash; Temperature Records</> }
                sectionBLink="/unit-1/1.2-carbon-from-the-past/#temperature-records"
                sectionC={ <>Reflect and Connect</> }
                sectionCLink="/unit-1/1.2-carbon-from-the-past/#reflect-and-connect"
              />

              <FocusQuestion
                focusQuestionBodyText={ <>What is the pattern for carbon dioxide (CO<sub>2</sub>) and temperature in Earth's past?</> }
              />

              <Introduction
                paragraphOne={
                  <>
                    <Row className="d-md-none">
                      <Col>
                        <Img
                          className="rounded img-fluid mb-3"
                          fluid={props.data.image9.childImageSharp.fluid}
                          alt="Geoscientists on work"
                        />
                      </Col>
                    </Row>
                    <Img
                      className="smallImageRight rounded ml-4 mb-2 img-fluid d-none d-md-block"
                      fluid={props.data.image9.childImageSharp.fluid}
                      alt="Geoscientists on work"
                    />

                    <p>In Lesson 1.1, you saw that CO<sub>2</sub> can enter and exit water. The activities were a good model for carbon moving between the ocean and air in the carbon cycle. Now think about the geologic past. Do you think there were times when more CO<sub>2</sub> was leaving than entering the ocean, or vice versa? How might you, or a <strong>geoscientist</strong>, be able to investigate CO<sub>2</sub> in Earth's past? This is an important question. Perhaps you've heard that the temperature of the atmosphere is related to CO<sub>2</sub> contents of the air.</p>
                  </>
                }

                paragraphTwo={
                  <>
                    <p>Think of the atmosphere as a key part of the Earth system. If CO<sub>2</sub> is leaving the oceans, it is probably moving to the atmosphere. Or, if CO<sub>2</sub> is entering the ocean, it is probably coming from the atmosphere. So, how could you <em>get samples of that prehistoric air?</em></p>
                  </>
                }

                paragraphThree={
                  <>
                    <p>You're in luck. Geoscientists have a clever way to get tiny samples of "old" air. Can you think of a natural material on Earth that captures a small sample of air as it forms? It is not obvious, but there is an earth material that does this: Snow!</p>
                  </>
                }

                paragraphFour={
                  <>
                    <p>In this lesson you will see that:</p>
                    <ul>
                      <li>Ice sheets preserve tiny samples of ancient air from Earth's past.</li>
                      <li>Samples of air from ice cores show the relationship between the CO<sub>2</sub> levels and temperature for climate in Earth's past.</li>
                    </ul>
                  </>
                }

                paragraphFive={
                  <>
                    <p>Later in <em>Carbon Connections</em>, you will return to see how these concepts closely connect to the carbon cycle. In this lesson, you will focus on understanding this question better.</p>
                  </>
                }
              />

              <ButtonTop />

              <Row>
                <Col>
                  <Card className="mb-4">
                    <Card.Header id="ice-cores" className="cardHeader">
                      <h2>
                        A. <span className="headerFontBlue">Ice Cores&mdash;CO<sub>2</sub> Records</span>
                      </h2>
                    </Card.Header>
                    <Card.Body className="cardBodyBackground">
                      <Row className="d-md-none">
                        <Col>
                          <Img
                            className="rounded img-fluid mb-3"
                            fluid={props.data.image10.childImageSharp.fluid}
                            alt="Ice cores"
                          />
                        </Col>
                      </Row>
                      <Img
                        className="smallImageRight rounded ml-4 img-fluid d-none d-md-block"
                        fluid={props.data.image10.childImageSharp.fluid}
                        alt="Ice cores"
                      />

                      <p>
                        Think about how snow might trap air. When snow falls, the flakes stack up. Between stacked flakes, tiny pockets of air get trapped. It's like the model with cereal flakes that your teacher showed you. If that snow melts, the air is released back into the atmosphere. For example, this happens each spring in cities and towns across the U.S., such Denver, Chicago, New York, or Boston, or maybe in your own town. No air inside the snow is preserved because it's melted.
                      </p>

                      <p>
                        Other places on Earth are cold all year. The snow does not melt, it piles up, layer upon layer. The best examples are near Earth's poles, such as on the ice sheets of Greenland or Antarctica. There, the temperature is almost always below freezing. As snow gets deeper, it hardens into glacial ice and preserves small pockets of air from the time when the snow fell. Deeper in the snowpack, the pockets of air contain older air. View the Air Between Flakes video to see how air gets preserved in snow.
                      </p>

                      <div className="embed-container mb-3">
                        <iframe title="snowball" className="videoSetting" src="https://player.vimeo.com/video/307588330" frameBorder="0" webkitallowfullscreen={toString('true')} mozallowfullscreen={toString('true')} allowFullScreen></iframe>
                      </div>

                      <Row className="d-md-none">
                        <Col>
                          <Img
                            className="rounded img-fluid mb-3"
                            fluid={props.data.image11.childImageSharp.fluid}
                            alt="Bubbles"
                          />
                        </Col>
                      </Row>
                      <Img
                        className="smallImageRight rounded ml-4 img-fluid d-none d-md-block"
                        fluid={props.data.image11.childImageSharp.fluid}
                        alt="Bubbles"
                      />

                      <p>
                        The air bubbles image to the right shows what ice looks like deep in an ice sheet. Under the increased weight of overlying snow and ice, a layer progressively changes from packed snow to ice. The largest ice sheets today are on <strong>Antarctica</strong> and <strong>Greenland</strong>. The layers in these ice sheets are of particular interest to geologists who study Earth's past climate. Part of understanding climate is retrieving and analyzing the CO<sub>2</sub> in those trapped air bubbles.
                      </p>

                      <p>
                        How can you measure CO<sub>2</sub> contents of air in the past? To do this, geologists drill a core into the ice sheet; the deeper they drill, the older the ice. The Young Ice, Old Ice animation shows how geologists get an ice core. The bubbles in that old ice have samples of old air. Then, the scientists measure the CO<sub>2</sub> content in the bubbles from different depths.
                      </p>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>

              <ButtonTop />

              <Row>
                <Col>
                  <Card className="mb-4">
                    <Card.Header className="cardHeader">
                      <span className="headerFontBlue"><h2>Young Ice, Old Ice</h2></span>
                    </Card.Header>
                    <Card.Body className="cardBodyBackground">
                      <div className="embed-container">
                        <iframe title="young ice, old ice" className="videoSetting" src="https://player.vimeo.com/video/307588955" frameBorder="0" webkitallowfullscreen={toString('true')} mozallowfullscreen={toString('true')} allowFullScreen></iframe>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>

              <ButtonTop />

              <Row>
                <Col>
                  <Card className="mb-4">
                    <Card.Header className="cardHeader">
                      <span className="headerFontBlue"><h2>Carbon Dioxide Concentrations, Antarctica</h2></span>
                    </Card.Header>
                    <Card.Body className="cardBodyBackground">
                      <p>
                        The graph below shows the CO<sub>2</sub> levels in the air bubbles of an ice core from Antarctica. The age of the ice (x-axis) as you go down the core goes from recent times (about 1,000 years ago) back to 650,000 years ago. It may not seem like 1,000 years ago is "recent," but this is only a small fraction of 650,000 years. On that scale it is relatively recent. The core penetrates more than three kilometers (nearly two miles) into the ice sheet. This distance is like going around your school track nearly eight times. That's deep! It takes many years to drill and recover a core like this.
                      </p>

                      <p>
                        The y-axis on the graph shows the CO<sub>2</sub> levels in air when the snow fell. The units are in parts per million (ppm) of CO<sub>2</sub>. You can think of parts per million like this: Think about visiting a large city like Dallas, Texas, which has a population of about one million people. As one person in a million people, you would represent one part per million.
                      </p>

                      <Row className="d-md-none">
                        <Col>
                          <img
                            className="largeImageCenter rounded img-fluid mb-3"
                            src={ AntarticaConcentrations12 }
                            alt="Carbon dioxide concentrations, Antarctica"
                          />
                        </Col>
                      </Row>
                      <img
                        className="largeImageCenter rounded img-fluid d-none d-md-block mb-3"
                        src={ AntarticaConcentrations12 }
                        alt="Carbon dioxide concentrations, Antarctica"
                      />

                      <p>
                        Explore this graph further while answering questions about CO<sub>2</sub> levels in the atmosphere in Earth's past. Record your answers in your science notebook.
                      </p>

                      <ol>
                        <li>What are the highest levels of CO<sub>2</sub> in the atmosphere in the past 650,000 years? Be specific about the five or six times when CO<sub>2</sub> levels were the highest. When were these?</li>
                        <li>What are the lowest levels of CO<sub>2</sub> in air in the past 650,000 years? When were five or six times with relatively low CO<sub>2</sub> levels in the air?</li>
                        <li>
                          Do you see patterns in the data? If so, how would you describe them? <br/>
                          <em>Hint: In other words, how often do peaks and valleys occur?</em>
                        </li>
                        <li>Answer these two questions about changes of CO<sub>2</sub> levels in the air:
                          <ol type="a">
                            <li>When CO<sub>2</sub> increased in the air, from where do you think it was coming?</li>
                            <li>When CO<sub>2</sub> decreased in the air, where do you think it went? </li>
                          </ol>
                        </li>
                      </ol>

                      <p>
                        So, why would geologists study the CO<sub>2</sub> levels in the atmosphere? Maybe you have heard that CO<sub>2</sub> levels in the atmosphere are related to Earth's climate. The ice core that you just explored also contains evidence for the climate and air temperature at the time the snow was falling. Using data from this ice core, continue with the next section to explore whether the air temperature at Antarctica is related to CO<sub>2</sub> levels in the atmosphere.
                      </p>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>

              <ButtonTop />

              <Row>
                <Col>
                  <Card className="mb-4">
                    <Card.Header id="temperature-records" className="cardHeader">
                      <h2>
                        B. <span className="headerFontBlue">Ice Cores&mdash;Temperature Records</span>
                      </h2>
                    </Card.Header>
                    <Card.Body className="cardBodyBackground">
                      <p>
                        Scientists have a detailed record of air temperatures above the ice sheet from the past. Levels of CO<sub>2</sub> come from the bubble, but temperature is measured from the ice (H<sub>2</sub>O) immediately around the bubble. This is possible because the chemistry of ice differs very slightly depending on the average air temperature.
                      </p>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>

              <ButtonTop />

              <Row>
                <Col>
                  <Card className="mb-4">
                    <Card.Header className="cardHeader">
                      <span className="headerFontBlue"><h2>Temperature Variations, Antarctica</h2></span>
                    </Card.Header>
                    <Card.Body className="cardBodyBackground">
                      <p>
                        The Temperature Variation graph shows temperature changes for air above the Antarctic ice sheet. As with the CO<sub>2</sub> graph, the age of the ice (x-axis) goes from recent times (about 1,000 years ago) to 650,000 years ago. The y-axis shows how average air temperature above the ice sheet has changed during that time. Even though Antarctica is always cold, the data show that the average temperature has varied. In this graph, the value of 0&deg;C is the mean value for the past 1,000 years. Along the length of the core, temperature values are either below or slightly above that value. Explore temperature changes further in the questions below.
                      </p>

                      <Row className="d-md-none">
                        <Col>
                          <img
                            className="largeImageCenter rounded img-fluid mb-3"
                            src={ AntarticaTemperatureVariations13 }
                            alt="Temperature variation, Antartica"
                          />
                        </Col>
                      </Row>
                      <img
                        className="largeImageCenter rounded img-fluid d-none d-md-block"
                        src={ AntarticaTemperatureVariations13 }
                        alt="Temperature variation, Antartica"
                      />

                      <a
                        href="http://media.bscs.org/carbonconnections/images/student_edition/02_curriculum/unit1/documents/co2_temperature_time_data.xls"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Button className="mt-3 mb-3">
                          Download CO<sub>2</sub> Temperature-time DATA for Ice Core&nbsp;&nbsp;<i className="fas fa-cloud-download-alt"></i>
                        </Button>
                      </a>

                      <ol start="5">
                        <li>Answer these questions about air temperatures in the past at Antarctica. Note that "recent" is not quite today on this timeline, but about 1,000 years ago, before the Industrial Revolution.
                          <ol type="a">
                            <li>Was the past warmer or colder than recent times? Recall that recent times are on the line at 0&deg;C on the y-axis.</li>
                            <li>Which times were warmer than recent times?</li>
                          </ol>
                        </li>

                        <li>
                          How do patterns in CO<sub>2</sub> levels compare with the temperature record? Use this sentence starter to frame your observation: <br />
                          <em>For times when the CO<sub>2</sub> record was <strong>increasing</strong>, I see that the temperature record &#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;</em>. <br />
                          <em>For times when the CO<sub>2</sub> record was <strong>at a low point</strong> or <strong>valley</strong>, I see that the temperature record &#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;</em>.
                        </li>

                        <Row className="d-md-none">
                          <Col>
                            <Img
                              className="rounded img-fluid mb-3 mt-3"
                              fluid={props.data.image14.childImageSharp.fluid}
                              alt="Guy working on ice"
                            />
                          </Col>
                        </Row>
                        <Img
                          className="smallImageRight rounded ml-4 img-fluid d-none d-md-block"
                          fluid={props.data.image14.childImageSharp.fluid}
                          alt="Guy working on ice"
                        />

                        <li>Complete the following steps about glacial and interglacial climate periods on Earth using a handout of the CO<sub>2</sub> or temperature graph.
                          <ol type="a">
                            <li>
                              Read the Antarctica Ice Sheet graph. <br />
                              You may have learned that during certain times in Earth's past, large areas of the continents were covered with large sheets of ice. The overall temperature for Earth's climate at those times was relatively cold. These time periods are called <strong>glacial periods</strong>, or <strong>ice ages</strong>. In contrast, <strong>interglacial periods</strong> are the relatively warm climates for Earth between glacial periods. This does not mean that there were no ice sheets; the ice sheets were just much smaller and restricted to regions at or close to the poles.
                            </li>

                            <li>About how many glacial periods do you see the past 650,000 years? Label these on your graph using a circle or arrow. (You can also list ages where glacial periods occurred.)</li>

                            <li>About how many interglacial periods do you see the past 650,000 years? Label these on your graph using a circle or arrow. (You can also list ages where interglacials occurred.)</li>

                            <li>
                              Complete this sentence in your notes: <br />
                              <em>Currently, the climate of Earth matches a(n) &#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F; period.</em>
                            </li>
                            <li>Watch the Glacial/Interglacial Cycle video to view past changes between glacial and interglacial periods.</li>
                          </ol>
                        </li>
                      </ol>

                      <div id="glacial-interglacial-cycle-video" className="embed-container mb-3">
                        <iframe title="ron pleistocene" className="videoSetting" src="https://player.vimeo.com/video/307590082" frameBorder="0" webkitallowfullscreen={toString('true')} mozallowfullscreen={toString('true')} allowFullScreen></iframe>
                      </div>

                      <Row className="d-md-none">
                        <Col>
                          <img
                            className="largeImagerounded img-fluid mb-3"
                            src={ AntarticaIceSheet15 }
                            alt="Antartica ice sheet (EPICA core)"
                          />
                        </Col>
                      </Row>
                      <img
                        className="largeImageCenter rounded mb-4 img-fluid d-none d-md-block"
                        src={ AntarticaIceSheet15 }
                        alt="Antartica ice sheet (EPICA core)"
                      />

                      <ol start="8">
                        <Row className="d-md-none">
                          <Col>
                            <img
                              className="rounded img-fluid mb-3 imageBorder"
                              src={ TempRelation16 }
                              alt="Carbon dioxide temperature relationship, Antartica"
                            />
                          </Col>
                        </Row>
                        <img
                          className="smallImageRight imageBorder rounded ml-4 mb-3 img-fluid d-none d-md-block"
                          src={ TempRelation16 }
                          alt="Carbon dioxide temperature relationship, Antartica"
                        />

                        <li>
                          You can show both CO<sub>2</sub> levels and temperature variation in the same graph. An example is the Antarctica Ice Sheet graph. It helps you compare the two variables together. You can show both CO<sub>2</sub> levels and temperature from ice cores in a single graph. The Antarctica Ice Sheet graph is called a <strong>double-y graph</strong>. This is because it has two, <strong>dependent variables</strong> plotted separately on each of the y-axes. The left y-axis shows CO<sub>2</sub> levels (units of ppm CO<sub>2</sub>), and the right y-axis shows temperature variation (&deg;C). The <strong>independent variable</strong> of Age of Ice (x-axis) is the same for both of the dependent variables (y-axis).
                        </li>

                        <li>
                          Show the relationship between variation in CO<sub>2</sub> level and temperature in the CO<sub>2</sub> Temperature Relationship graph. You can plot actual values for data from the ice core, or just estimate the trend. <br />
                          <em>Hint: The axes in the CO<sub>2</sub> Temperature Relationship are for temperature at Antarctica (y-axis) with CO<sub>2</sub> levels in the atmosphere (x-axis). Select four or five points from the Antarctica Ice Sheet graph with low, middle, and high values of CO<sub>2</sub>. Find the corresponding temperature for those CO<sub>2</sub> values and plot those points on the graph below.</em>
                        </li>

                        <li>As your teacher directs, watch the next video, "How Do We Know?". It will tell a little more about measuring past climates from ice cores.</li>
                      </ol>

                      <div className="embed-container">
                        <iframe title="wais co2 ice layers" className="videoSetting" src="https://player.vimeo.com/video/307590788" frameBorder="0" webkitallowfullscreen={toString('true')} mozallowfullscreen={toString('true')} allowFullScreen></iframe>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>

              <ButtonTop />

              <Row>
                <Col>
                  <Card className="mb-4">
                    <Card.Header id="reflect-and-connect" className="cardHeader">
                      <h2>
                        <span className="headerFontBlue">Explore More: Carbon Cycle Connections</span>
                      </h2>
                    </Card.Header>
                    <Card.Body className="cardBodyBackground">
                      <p className="lead">
                        Carbon Cycle Connections
                      </p>
                      <p>
                        In Lesson 1.1, you investigated carbon as CO<sub>2</sub>, moving in and out of water. In this lesson, you saw how CO<sub>2</sub> levels have changed in Earth's past in the atmosphere. For a period where CO<sub>2</sub> is increasing in the air, does the CO<sub>2</sub> just come from the oceans? What changes might be occurring on land?
                      </p>

                      <p>
                        Maybe you have explored photosynthesis in your science class. <strong>Photosynthesis</strong> is the process where plants and other CO<sub>2</sub> producers use water (H<sub>2</sub>O), carbon dioxide (CO<sub>2</sub>), and energy from the sun to produce organic molecules (e.g., C<sub>3</sub>H<sub>6</sub>O<sub>3</sub>) and oxygen (O<sub>2</sub>). This process moves carbon from the atmosphere (in CO<sub>2</sub>) to living organisms.
                      </p>

                      <p>
                        You saw from the Antarctica ice core that there are times when CO<sub>2</sub> was decreasing in the atmosphere. Perhaps producers account for these dramatic changes in CO<sub>2</sub> in the air. Recall that <strong>primary producers</strong>, or <strong>autotrophs</strong>, include a variety of plants, algae, and bacteria in food webs. Photosynthesis moves carbon from the atmosphere to the oceans or land. The photosynthesis reaction is:
                      </p>

                      <p className="text-center">
                        3CO<sub>2</sub> + 3H<sub>2</sub>O + energy &rarr; C<sub>3</sub>H<sub>6</sub>O<sub>3</sub> + 3O<sub>2</sub> <br />
                        carbon dioxide + water + energy &rarr; organic molecules + oxygen
                      </p>

                      <p>
                        Explore how carbon on the land changes as carbon in the atmosphere changes by looking at ecosystems in the past. Changes between glacial and interglacial periods is a good timeframe to compare. Do the following steps to investigate further carbon cycling in Earth's past.
                      </p>

                      <Row className="d-md-none">
                        <Col>
                          <img
                            className="largeImageCenter rounded img-fluid mb-3"
                            src={ AntarticaIceSheet17 }
                            alt="Antartica ice sheet (EPICA core)"
                          />
                        </Col>
                      </Row>
                      <img
                        className="largeImageCenter rounded img-fluid mb-3 d-none d-md-block"
                        src={ AntarticaIceSheet17 }
                        alt="Antartica ice sheet (EPICA core)"
                      />

                      <ol>
                        <li>
                          Antarctica CO<sub>2</sub> and temperature data is shown in the CO<sub>2</sub> and Temperature Data graph. This shows the record of CO<sub>2</sub> and temperature going back to 150,000 years; the Antarctica Ice Sheet graph you looked at went back to 650,000 years ago. Two highlighted regions of the curve are at 20,000 years ago and recent times. (Recall that "recent" is not today on this timeline, but about 1,000 years ago, before the Industrial Revolution.)
                          <ol type="a">
                            <li>What was the CO<sub>2</sub> level 20,000 years ago? Was temperature relatively warm or cool?</li>
                            <li>What is the CO<sub>2</sub> level at recent times? Have CO<sub>2</sub> levels increased or decreased from 20,000 years ago?</li>
                            <li>Consider 20,000 years ago and recent times. Do these times correspond to glacial or interglacial periods?</li>
                          </ol>
                        </li>
                      </ol>

                      <Row className="d-md-none">
                        <Col>
                          <img
                            className="largeImageCenter rounded img-fluid mb-3 imageBorder"
                            src={ PresentPotentialVegetation18 }
                            alt="Present potential vegetation"
                          />
                        </Col>
                      </Row>
                      <img
                        className="largeImageCenter imageBorder rounded img-fluid d-none d-md-block mb-3"
                        src={ PresentPotentialVegetation18 }
                        alt="Present potential vegetation"
                      />

                      <ol start="2">
                        <li>
                          You've looked at records of carbon as CO<sub>2</sub> in the air. Combine this with the relative amounts of carbon stored on land. Land carbon includes all soils and vegetation. The Present Potential Vegetation map shows a time before modern agriculture, about 1,000 years ago. For this time, areas with closed forests are in green (more than 70 percent coverage by trees). Areas of extreme desert are in yellow (less than 2 percent vegetation cover).
                          <ol type="a">
                            <li>What are the three or four regions on Earth with the most vegetation?</li>
                            <li>How do you think that the amount of vegetation relates to soils and the carbon on land?</li>
                            <li>Using the graphs, were CO<sub>2</sub> levels in the atmosphere at this time high or low?</li>
                          </ol>
                        </li>
                      </ol>

                      <Row className="d-md-none">
                        <Col>
                          <img
                            className="largeImageCenter rounded img-fluid mb-3 imageBorder"
                            src={ LastGlacialMax19 }
                            alt="Last glacial maximum (18,000 to 20,000 years ago)"
                          />
                        </Col>
                      </Row>
                      <img
                        className="largeImageCenter rounded mb-3 img-fluid d-none d-md-block imageBorder"
                        src={ LastGlacialMax19 }
                        alt="Last glacial maximum (18,000 to 20,000 years ago)"
                      />

                      <ol start="3">
                        <li>
                          Now look at the Last Glacial Maximum map of ecosystems at about 20,000 years ago. This was the last <strong>Ice Age</strong>, when large ice sheets covered much of North America, northern Europe, and Asia. Sometimes this is also called the <strong>Last Glacial Maximum</strong> (LGM). Compared with the previous map, the color key is the same (closed forest, green; extreme desert, yellow), but the patterns are very different. It is estimated that less than five percent of land has coverage from trees and forests. Extreme deserts are widespread.
                          <ol type="a">
                            <li>How do you think that the carbon in soils of deserts compares with the carbon in soils of forests?</li>
                            <li>Using the graphs, what were CO<sub>2</sub> levels in the atmosphere at this time?</li>
                            <li>Watch the video, "<Link to="/unit-1/1.2-carbon-from-the-past/#glacial-interglacial-cycle-video">Glacial/Interglacial Cycle</Link>," which shows the location and size of ice sheets during glacial and interglacial periods. Look at how the ice sheets cover large areas of land with soils and forest in more recent times.</li>
                            <li>Use the CO<sub>2</sub> and temperature graphs to infer which map would most likely apply to 125,000 years ago. Check your thinking with a classmate.</li>
                          </ol>
                        </li>
                      </ol>

                      <table className="table table-bordered table-hover table-responsive-md studentTable">
                        <thead>
                          <tr className="text-center">
                            <th className="table-active" scope="col">Factor</th>
                            <th className="table-active" scope="col">Does the factor <strong>increase</strong> (&uarr;) or <strong>decrease</strong> (&darr;) from 20,000 years ago to today?</th>
                            <th className="table-active" scope="col">Does the factor <strong>increase</strong> (&uarr;) or <strong>decrease</strong> (&darr;) from 125,000 to 20,000?</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>carbon in atmosphere (as CO<sub>2</sub>)</td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>average temperature over Antarctica</td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>mean temperature in North America and Eurasia</td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>amount of vegetation on land</td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>amount of carbon on land</td>
                            <td></td>
                            <td></td>
                          </tr>
                        </tbody>
                      </table>

                      <ol start="4">
                        <li>Explore the time from the last glacial maximum 20,000 years ago to recent times. This is the first column in the Factor table. Use the Factor table to help you organize your thinking. Use arrows in the boxes to show an increase (&uarr;) or decrease (&darr;) for the period.
                          <ol type="a">
                            <li>
                              Over that period, did the amount of CO<sub>2</sub> in the atmosphere increase or decrease? <br />
                              <em>Hint: If you are not sure, return to check the CO<sub>2</sub> Temperature Data graph above for CO<sub>2</sub> levels.</em>
                            </li>
                            <li>Over that period, did the carbon on land (soils, vegetation) increase or decrease?</li>
                            <li>How does the change in CO<sub>2</sub> level and carbon in ecosystems compare with the climate and temperature in Antarctica? For relative amount of carbon, use words like "high" or "low."</li>
                            <li>How can you explain the changes you see for carbon in land and CO<sub>2</sub> in atmosphere?</li>
                          </ol>
                        </li>

                        <li>Investigate the period from about 125,000 years ago to the last glacial maximum at 20,000 years ago. This is the second column in the Factor table. Use arrows in the boxes to show an increase (&uarr;) or decrease (&darr;) for the period.
                          <ol type="a">
                            <li>Over that period, did the amount of CO<sub>2</sub> in the atmosphere increase or decrease?</li>
                            <li>
                              Over that period, did the carbon on land (soils, vegetation) increase or decrease? <br />
                              <em>Hint: Recall what you inferred about the ecosystems at 125,000 years ago in 3d above.</em>
                            </li>
                            <li>How does the change in CO<sub>2</sub> level and carbon in ecosystems compare with the climate and temperature in Antarctica? For relative amount of carbon, use words like "high" or "low."</li>
                          </ol>
                        </li>

                        <li>If CO<sub>2</sub> is decreasing in the atmosphere, and carbon as vegetation is decreasing on land, think about where that carbon might go, using these steps.
                          <ol type="a">
                            <li>What is a part of Earth that could accept, or "soak-up," some of that carbon? </li>
                            <li>What investigation did you do that would help you understand where that carbon could go?</li>
                            <li>Using data from graphs in the lesson, identify some periods when carbon was decreasing in the atmosphere.</li>
                          </ol>
                        </li>
                      </ol>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>

              <ButtonTop />

              <Row>
                <Col>
                  <Card className="mb-4">
                    <Card.Header id="reflect-and-connect" className="cardHeader">
                      <h2>
                        C. <span className="headerFontBlue">Reflect and Connect</span>
                      </h2>
                    </Card.Header>
                    <Card.Body className="cardBodyBackground">
                      <Row className="d-md-none">
                        <Col>
                          <Img
                            className="rounded img-fluid mb-3"
                            fluid={props.data.image20.childImageSharp.fluid}
                            alt="Penguin"
                          />
                        </Col>
                      </Row>
                      <Img
                        className="smallImageRight rounded ml-4 img-fluid d-none d-md-block"
                        fluid={props.data.image20.childImageSharp.fluid}
                        alt="Penguin"
                      />

                      <p>
                        Summarize your ideas for Lesson 1.2, using the figures in the lesson to help you. If helpful, work with a partner; he or she might have some ideas to help you get the big picture.
                      </p>

                      <ol start="11">
                        <li>Return to answer your focus question for Lesson 1.2: <strong>Return to answer your focus question for Lesson 1.2: What is the pattern for carbon dioxide (CO<sub>2</sub>) and temperature in Earth's past?</strong>
                        </li>
                        <li>Make a sketch in your notebook of a diagram that shows a carbon atom moving from the ocean to a tree near your home. What different forms does the carbon atom take en route?</li>
                      </ol>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>

              <ButtonTop />

              <ButtonNext
                ButtonText="1.3 Carbon Forcing"
                NextLink="/unit-1/1.3-carbon-forcing/"
              />

            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default CarbonFromThePast12

const panels = [
  {
    heading: <></>,
    content:
    <>

    </>
  }
]

export const query = graphql`
  query {
    image9: file(relativePath: { eq: "student-pages/unit-1/09_geoscientists.png" }) {
      ...rowImage
    }
    image10: file(relativePath: { eq: "student-pages/unit-1/10_ice_cores.png" }) {
      ...rowImage
    }
    image11: file(relativePath: { eq: "student-pages/unit-1/11_bubbles.jpg" }) {
      ...rowImage
    }
    image14: file(relativePath: { eq: "student-pages/unit-1/14_ice_guy.jpg" }) {
      ...rowImage
    }
    image20: file(relativePath: { eq: "student-pages/unit-1/20_penguin.jpg" }) {
      ...rowImage
    }
  }
`
